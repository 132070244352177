import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';

import { processQueryResults } from 'core/utils/query';

const TOPIC_FIELDS = 'headline,list_headline,alternative_headline,link,topic_type';

const COMMON_ANNOUNCE = {
  limit: 1,
  include: 'rubric,image,tags',
  fields: TOPIC_FIELDS,
  sort: 'list',
};

export default function useMainTopics({ history }) {
  const {
    data: [
      popular,
      main,
      gallery,
      quote,
      playlist,
      unclipped,
      clipped,
      gameCompare,
    ],
    isLoading,
  } = processQueryResults(useRequest({
    queries: [
      topicsQuery({
        history,
        sort: '-views_count',
        limit: 5,
        include: 'image,rubric,photo_gallery,tags',
        fields: TOPIC_FIELDS,
        visibility: 'main_page',
        renderError: () => [],
      }),
      topicsQuery({
        history,
        limit: 3,
        include: 'image,rubric,photo_gallery,tags',
        fields: TOPIC_FIELDS,
        list: 'main',
        sort: 'list',
        renderError: () => [],
      }),
      topicsQuery({
        history,
        topic_type: 'gallery',
        include: 'image,photo_gallery',
        fields: TOPIC_FIELDS,
        list: 'gallery',
        sort: 'list',
        renderError: () => [],
      }),
      topicsQuery({
        history,
        limit: 1,
        include: 'content',
        fields: 'link',
        list: 'quote',
        sort: 'list',
        renderError: () => [],
      }),
      topicsQuery({
        history,
        limit: 1,
        include: 'content',
        fields: '',
        list: 'playlist',
        sort: 'list',
        renderError: () => [],
      }),
      topicsQuery({
        ...COMMON_ANNOUNCE,
        history,
        list: 'super-announce-unclipped',
        renderError: () => [],
      }),
      topicsQuery({
        ...COMMON_ANNOUNCE,
        history,
        list: 'super-announce-clipped',
        renderError: () => [],
      }),
      topicsQuery({
        history,
        limit: 1,
        include: 'content',
        fields: 'headline,list_headline,link,alternative_headline,topic_type',
        list: 'contest',
        renderError: () => [],
      }),
    ],
  }));

  if (isLoading) return { isLoading: true };

  return {
    popular,
    main,
    gallery,
    quote,
    playlist,
    unclipped,
    clipped,
    gameCompare,
  };
}
