import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { processQueryResults } from 'core/utils/query';

import PageLoader from 'core/components/Loader/PageLoader';
import { Indent } from 'core/components/Wrappers';

import AlphabetWrapper from '../AlphabetWrapper';
import Selectors from '../Selectors';

import listPageSymbolsQuery from 'site/queries/listPageSymbolsQuery';
import listPageTagsQuery, { additionalTagsQueries } from 'site/queries/listPageTagsQuery';


function FiltersWrapper(props) {
  const {
    toggleFilter,
    queryParams,
    ...otherProps
  } = props;

  const { first_char: char } = queryParams;

  const {
    data: [
      symbols,
      geo,
      genre,
    ],
    isLoading,
  } = processQueryResults(useRequest({
    queries: [
      listPageSymbolsQuery(),
      listPageTagsQuery({ tagType: 'geo' }),
      listPageTagsQuery({ tagType: 'genre' }),
    ],
  }));

  const {
    data: additionalGeoResults,
    isLoading: additionalGeoResultsIsLoading,
  } = processQueryResults(useRequest({
    queries: additionalTagsQueries({ firstPart: geo, tagType: 'geo' }),
  }));

  const {
    data: additionalGenreResults,
    isLoading: additionalGenreResultsIsLoading,
  } = processQueryResults(useRequest({
    queries: additionalTagsQueries({ firstPart: genre, tagType: 'genre' }),
  }));

  if (isLoading || additionalGeoResultsIsLoading || additionalGenreResultsIsLoading) return <PageLoader />;

  let finalGeos = geo.data;
  if (additionalGeoResults?.length > 0) {
    const additional = additionalGeoResults.map(({ data }) => data).flat();
    finalGeos = finalGeos.concat(additional);
  }

  let finalGenres = genre.data;
  if (additionalGenreResults?.length > 0) {
    const additional = additionalGenreResults.map(({ data }) => data).flat();
    finalGenres = finalGenres.concat(additional);
  }

  return (
    <Indent top={20} bottom={30}>
      <AlphabetWrapper
        {...char && { char: decodeURIComponent(char) }}
        symbols={symbols}
        toggleFilter={toggleFilter}
      />
      <Selectors
        geo={finalGeos}
        genre={finalGenres}
        toggleFilter={toggleFilter}
        filterValues={{ ...queryParams }}
        {...otherProps}
      />
    </Indent>
  );
}

FiltersWrapper.propTypes = {
  toggleFilter: PropTypes.func,
  queryParams: PropTypes.object,
};

export default FiltersWrapper;
