import Page from 'core/components/Page';
import LegalPageCore from 'core/components/LegalPage';

import TermsOfUse from './TermsOfUse';
import Privacy from './Privacy';

export default function LegalPage() {
  return (
    <Page>
      <LegalPageCore
        termsOfUseContent={<TermsOfUse />}
        privacyContent={<Privacy />}
      />
    </Page>
  );
}
