import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import queryString from 'core/libs/query-string';


const EmbedContext = React.createContext(false);

function EmbedProvider({ location: { search }, children }) {
  const queryFilters = queryString.parse(search);
  const { embed_mode: embedMode } = queryFilters;

  return (
    <EmbedContext.Provider value={!!embedMode}>
      {children}
    </EmbedContext.Provider>
  );
}

EmbedProvider.propTypes = {
  location: PropTypes.object.isRequired,
};

const EmberProviderWithHOCs = withRouter(EmbedProvider);

export { EmberProviderWithHOCs as EmbedProvider };
export default EmbedContext;
