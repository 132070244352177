import { useState } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'core/components/Gallery';
import { withBreakpoint } from 'core/components/breakpoint';
import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';
import { createTopicsImages } from './utils';

function GalleryTopics({ topics, isMobile }) {
  const [photoGallery] = useState(() => {
    const images = topics.reduce(createTopicsImages, []);
    return {
      attributes: {
        images,
        image_count: images.length,
      },
      type: 'galleryOfTopics',
      id: 'galleryOfTopics',
    };
  });

  return (
    <Gallery
      photoGallery={photoGallery}
      imageVersionName={isMobile ? 'main_gallery' : 'original'}
      disableReloadButton
      isInfinite
    />
  );
}

GalleryTopics.propTypes = {
  isMobile: PropTypes.bool,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
};

export default withBreakpoint(GalleryTopics);
