import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';

import { rawRubricQuery, rawTopicsQuery } from 'core/queries/rubric';
import useRequest from 'core/hooks/useRequest';

import Page from 'core/components/Page';
import ListPage from 'core/components/ListPage';
import H1 from 'core/components/H1';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import EmptyWrapper from 'core/components/EmptyWrapper';
import PageLoader from 'core/components/Loader/PageLoader';

import { filterRequiredParams } from 'core/utils/api';
import { replaceCurrentYear } from 'core/utils/string-helper';

import { PageIndent, Indent, MaxWidthWrapper } from 'site/components/Wrappers';
import RubricTopics from 'site/components/RubricTopics';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import CardVertical from 'site/cards/CardVertical';
import CardStory from 'site/cards/CardStory';
import CardMainHero from 'site/cards/CardMainHero';
import CompareCard from 'site/cards/CompareCard';


function RubricPage(props) {
  const { location, match, history } = props;
  const { type } = queryString.parse(location.search);

  const [
    { data: rawTopics, isLoading: topicsAreLoading },
    { data: rawRubric, isLoading: rubricIsLoading },
  ] = useRequest({
    queries: [
      rawTopicsQuery({
        location,
        match,
        history,
        topic_type: type === 'news' ? 'news' : '-news',
        limit: RUBRICS_PAGE_LIMIT,
        include: filterRequiredParams([CardVertical, CardMainHero, CardStory, CompareCard], 'include'),
        fields: filterRequiredParams([CardVertical, CardMainHero, CardStory, CompareCard], 'fields'),
        with_filtered_count: 1,
      }),
      rawRubricQuery({ match, history }),
    ],
  });

  if (rubricIsLoading || topicsAreLoading) return <PageLoader />;

  const {
    data: {
      attributes: {
        title,
        description,
        meta_title: metaTitle,
        meta_description: metaDescription,
      },
    },
  } = rawRubric;

  const titleMeta = replaceCurrentYear(metaTitle);
  const descriptionMeta = replaceCurrentYear(metaDescription);
  const Wrapper = type === 'news' ? MaxWidthWrapper : EmptyWrapper;

  return (
    <Page
      title={titleMeta || `${title} — все статьи и новости`}
      description={descriptionMeta}
    >
      <PageIndent>
        <Wrapper>
          <H1>{title}</H1>
          <Indent top={20} />
          {!!description && (
            <div style={{ marginBottom: '20px' }}>
              <MarkdownWrapper>{description}</MarkdownWrapper>
            </div>
          )}
          <ListPage
            limit={RUBRICS_PAGE_LIMIT}
            rawData={rawTopics}
            isLoading={topicsAreLoading || rubricIsLoading}
          >
            {({ content }) => <RubricTopics topics={content} topicType={type} />}
          </ListPage>
        </Wrapper>
      </PageIndent>
    </Page>
  );
}

RubricPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default RubricPage;
