import Link from 'core/components/Link';
import Indent from 'core/components/Wrappers/Indent';

import styles from '../index.styl';

export default function TermsOfUse() {
  return (<>
    <Indent bottom={40} >
      <i>Условия использования определяют условия, на которых Компания предоставляет Сервис.</i>
    </Indent>
    <ol>
      <li>
        <h3 className={styles.subtitle}>Термины</h3>
        <ol>
          <li><b>Компания</b>&nbsp;&mdash; юридическое лицо, предоставляющее Пользователям Сервис. Наименование Компании размещено на&nbsp;Ресурсе.</li>
          <li><b>Ресурс</b>&nbsp;&mdash; сайт, программное обеспечение и/или иной интернет-ресурс Компании.</li>
          <li><b>Сервис</b>&nbsp;&mdash; функционал Ресурса, предоставляемый Компанией, в&nbsp;том числе предоставление доступа к&nbsp;Ресурсу или его части, Контенту и&nbsp;иным материалам.</li>
          <li><b>Пользователь</b>&nbsp;&mdash; физическое лицо, использующее Сервис.</li>
          <li><b>Контент</b>&nbsp;&mdash; дизайн, графика, изображения, фотографии, тексты, статьи, видео, музыка, песни, программное обеспечение, базы данных, товарные знаки, логотипы, любые иные результаты интеллектуальной деятельности и&nbsp;средства индивидуализации, размещенные или размещаемые на&nbsp;Ресурсе.</li>
          <li><b>Условия</b>&nbsp;&mdash; настоящие Условия использования, являющиеся соглашением между Компанией и&nbsp;Пользователем и&nbsp;устанавливающие права и&nbsp;обязанности по&nbsp;использованию Сервиса.</li>
          <li><b>Правила применения рекомендательных технологий</b>&nbsp;&mdash; неотъемлемая часть Условий, определяющая общие условия и&nbsp;правила применения информационных технологий предоставления информации на&nbsp;основе сбора, систематизации и&nbsp;анализа сведений, относящихся к&nbsp;предпочтениям Пользователей.</li>
          <li><b><Link to={'/legal/mailing-rules'}>Условия рассылок</Link></b>&nbsp;&mdash; часть Условий, содержащая условия, на&nbsp;которых Компания предоставляет Сервис, позволяющий Пользователям получать Сообщения.</li>
          <li><b><Link to={'/legal/privacy'}>Политика конфиденциальности</Link></b>&nbsp;&mdash; документ, определяющий политику Компании в&nbsp;отношении обработки данных Пользователей при использовании ими Сервиса, а&nbsp;также содержащий сведения о&nbsp;реализуемых требованиях к&nbsp;защите персональных данных Пользователей.</li>
          <li><b>Документы</b>&nbsp;&mdash; совместно Условия, <Link to={'/legal/mailing-rules'}>Условия рассылок</Link> и&nbsp;<Link to={'/legal/privacy'}>Политика конфиденциальности</Link>, а&nbsp;также пользовательские соглашения отдельных Сервисов и&nbsp;иные документы, регулирующие отношения между Компанией и&nbsp;Пользователем по&nbsp;предоставлению и&nbsp;использованию Сервиса, размещенные на&nbsp;Ресурсе.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Действие условий</h3>
        <ol>
          <li>Условия заключаются в&nbsp;соответствии с&nbsp;нормами законодательства&nbsp;РФ, регулирующими принятие публичной оферты конклюдентными действиями. Условия считаются заключенными с&nbsp;момента их&nbsp;принятия Пользователем. Использование Сервиса (включая осуществление доступа к&nbsp;Ресурсу) означает, что Пользователь полностью и&nbsp;безоговорочно принял Условия. Условия обязательны для исполнения Пользователем и&nbsp;Компанией с&nbsp;момента их&nbsp;заключения. Если Пользователь не&nbsp;согласен с&nbsp;Условиями, он&nbsp;не&nbsp;вправе использовать Сервис, в&nbsp;том числе осуществлять доступ к&nbsp;Ресурсу.</li>
          <li>Условия могут быть изменены Компанией. Измененные Условия размещаются на&nbsp;Ресурсе. Используя Сервис, после изменения Условий, Пользователь принимает Условия полностью и&nbsp;безоговорочно в&nbsp;измененном виде.</li>
          <li>Для расторжения Условий Пользователю необходимо прекратить любое использование Сервиса, включая осуществление доступа к&nbsp;Ресурсу.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Использование сервиса</h3>
        <ol>
          <li>Необходимым и&nbsp;обязательным условием использования Сервиса является соблюдение Пользователем прав и&nbsp;законных интересов Компании, других Пользователей и&nbsp;иных третьих лиц, а&nbsp;также законодательства&nbsp;РФ.</li>
          <li>Условием предоставления Сервиса является наличие рекламы на&nbsp;Ресурсе, если иное не&nbsp;предусмотрено Документами в&nbsp;отношении отдельного Сервиса. Пользователь осознает и&nbsp;соглашается, что Ресурс может содержать рекламу. При этом Пользователь не&nbsp;вправе размещать на&nbsp;Ресурсе рекламу и&nbsp;ссылки на&nbsp;рекламу, если иное не&nbsp;предусмотрено пользовательским соглашением отдельного Сервиса.</li>
          <li>Сервис предназначен для личного некоммерческого использования, не&nbsp;связанного с&nbsp;осуществлением предпринимательской деятельности, если иное не&nbsp;предусмотрено Документами в&nbsp;отношении отдельного Сервиса.</li>
          <li>Пользователь самостоятельно несет ответственность за&nbsp;все действия, совершаемые им&nbsp;на&nbsp;Ресурсе, за&nbsp;нарушение законодательства РФ, Условий и&nbsp;иных Документов. Компания не&nbsp;несет ответственности за&nbsp;любые виды убытков, произошедшие вследствие использования Сервиса.</li>
          <li>Сервис предоставляется &laquo;как есть&raquo;. Компания не&nbsp;гарантирует, что Сервис и&nbsp;результат его использования будет удовлетворять ожиданиям Пользователя. Пользователь принимает все последствия и&nbsp;самостоятельно несёт все возможные риски, связанные с&nbsp;использованием Сервиса.</li>
          <li>
            <p>Для предоставления отдельных Сервисов необходимы:</p>
            <ul>
              <li>направление Пользователю сервисных сообщений;</li>
              <li>применение рекомендательных технологий. Такие технологии применяются на&nbsp;Ресурсе в&nbsp;соответствии&nbsp;с Правилами применения рекомендательных технологий;</li>
              <li>обработка данных Пользователей. Такая обработка осуществляется в соответствии с условиями <Link to={'/legal/privacy'}>Политики конфиденциальности</Link>. В&nbsp;случае, если Пользователь предоставляет Компании данные третьих лиц, он&nbsp;гарантирует Компании наличие правового основания на&nbsp;их&nbsp;обработку в&nbsp;соответствии с&nbsp;условиями применимых Документов, а&nbsp;также уведомление таких лиц о&nbsp;соответствующей обработке Компанией и&nbsp;иными указанными в&nbsp;Документах лицами.</li>
            </ul>
          </li>
          <li>
            <p>При использовании Пользователем соответствующего Сервиса для исполнения Условий необходима обработка Компанией данных Пользователя, включая их&nbsp;предоставление третьим лицам для последующей обработки в&nbsp;рамках следующих процессов:</p>
            <ul>
              <li>для сопровождения деятельности Компании провайдером услуг (ООО &laquo;РИХ&raquo;, ООО &laquo;БРИ&raquo; по&nbsp;поручению Компании);</li>
              <li>для персонализации предложений ООО &laquo;РА&nbsp;&laquo;Индекс&nbsp;20&raquo; (с&nbsp;последующим предоставлением ПАО Сбербанк, ООО &laquo;Облачные технологии&raquo;).</li>
            </ul>
          </li>
          <li>Для предоставления Сервисов на&nbsp;Ресурсе используются cookies (небольшие фрагменты данных о&nbsp;прошлых посещениях). Cookies необходимы в&nbsp;том числе для обеспечения функционирования Сервиса; персонализации Контента и&nbsp;иных предложений; проведения статистических и&nbsp;иных исследований использования Сервиса. Пользователь может просмотреть срок действия cookies, а&nbsp;также запретить их&nbsp;сохранение в&nbsp;настройках своего браузера. Сookies обрабатываются в&nbsp;том числе с&nbsp;использованием сервисов веб-аналитики, в&nbsp;частности, Статистика от&nbsp;SberAds, Яндекс.Метрика, Рейтинг Mail.ru, LiveInternet, Mediascope.</li>
          <li>В&nbsp;случае отзыва Пользователем согласия на&nbsp;обработку персональных данных Компания вправе продолжить их&nbsp;обработку для исполнения Условий (или иных соглашений между Компании и&nbsp;Пользователем) или на&nbsp;иных основаниях, предусмотренных законодательством&nbsp;РФ.</li>
          <li>Компания предоставляет Пользователю Сервис, позволяющий получать сообщения информационного, новостного, рекламного или иного характера при условии осуществления подписки в&nbsp;соответствии&nbsp;с <Link to={'/legal/mailing-rules'}>Условиями рассылок</Link>.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Иное</h3>
        <ol>
          <li>Отношения Компании и&nbsp;Пользователя, в&nbsp;том числе предусмотренные Условиями, регулируются законодательством&nbsp;РФ.</li>
          <li>Пользователь соглашается, что Компания вправе передать все свои права и&nbsp;обязанности по&nbsp;Документам, в&nbsp;том числе Условиям, другому лицу (например, при продаже Ресурса или иных изменениях).</li>
          <li>Все споры, возникающие из&nbsp;Условий и/или связанные с&nbsp;Ресурсом и&nbsp;Контентом, могут быть переданы на&nbsp;разрешение суда после принятия Сторонами мер по&nbsp;досудебному урегулированию спора по&nbsp;истечении 30&nbsp;календарных дней со&nbsp;дня направления первой претензии. Если спор не&nbsp;урегулирован в&nbsp;досудебном порядке согласно настоящему пункту, он&nbsp;может быть передан на&nbsp;разрешение суда по&nbsp;месту нахождения Компании.</li>
          <li>По&nbsp;настоящим Условиям Компания предоставляет Сервис безвозмездно, поэтому нормы о&nbsp;защите прав потребителей не&nbsp;применяются.</li>
          <li>Юридически значимые сообщения в&nbsp;адрес Компании направляются по&nbsp;адресу: <i>117105, г. Москва, Варшавское&nbsp;ш., д.&nbsp;9, стр.&nbsp;1.</i></li>
        </ol>
      </li>
    </ol>
    <p className={styles.editorial}><i>Редакция от 6 декабря 2024</i></p>
  </>
  );
}
