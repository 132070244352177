import PropTypes from 'prop-types';

import AboutCore from 'core/components/About';
import PageLoader from 'core/components/Loader/PageLoader';

import useRequest from 'core/hooks/useRequest';
import { aboutQuery } from 'core/queries/about';


function About({ history }) {
  const { data: content, isLoading } = useRequest(aboutQuery({ history }));

  if (isLoading) return <PageLoader />;

  return (
    <AboutCore
      content={content}
      mapSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2246.307723899861!2d37.529333415863356!3d55.73578210077214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54bbe67327cb3%3A0x2b8643e64aeb2713!2z0J_QvtC60LvQvtC90L3QsNGPINGD0LsuLCAzLCDQnNC-0YHQutCy0LAsIDEyMTE3MA!5e0!3m2!1sru!2sru!4v1658379293417!5m2!1sru!2sru" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    />
  );
}

About.propTypes = {
  history: PropTypes.object,
};

export default About;
