
import styles from '../index.styl';

export default function Privacy() {
  return (<>
    <p><i>Политика конфиденциальности определяет политику Компании в отношении обработки Данных Пользователей при использовании ими Сервиса, а также содержит сведения о реализуемых требованиях к защите персональных данных Пользователей.</i></p>
    <ol>
      <li>
        <h3 className={styles.subtitle}>Термины</h3>
        <ol>
          <li><b>Компания</b>&nbsp;&mdash; юридическое лицо, предоставляющее Пользователям Сервис. Наименование Компании размещено на&nbsp;Ресурсе.</li>
          <li><b>Ресурс</b>&nbsp;&mdash; сайт, программное обеспечение и/или иной интернет-ресурс Компании.</li>
          <li><b>Сервис</b>&nbsp;&mdash; функционал Ресурса, предоставляемый Компанией, в&nbsp;том числе предоставление доступа к&nbsp;Ресурсу или его части, Контенту и&nbsp;иным материалам.</li>
          <li><b>Пользователь</b>&nbsp;&mdash; физическое лицо, использующее Сервис.</li>
          <li><b>Данные</b>&nbsp;&mdash; персональные данные Пользователя, а&nbsp;также иная пользовательская информация.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Действие политики</h3>
        <ol>
          <li>Используя Сервис (включая осуществление доступа к&nbsp;Ресурсу), Пользователь соглашается с&nbsp;условиями обработки Данных Компанией, описанными в&nbsp;настоящей Политике конфиденциальности (Политика). В&nbsp;случае несогласия Пользователя с&nbsp;такими условиями, использование Сервиса, в&nbsp;том числе осуществление доступа к&nbsp;Ресурсу, должно быть прекращено.</li>
          <li>Политика может быть изменена Компанией. Измененная Политика размещается на&nbsp;Ресурсе. Используя Сервис после изменения Политики, Пользователь соглашается с&nbsp;измененными условиями обработки Данных.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Цель и условия обработки</h3>
        <ol>
          <li>Компания обрабатывает Данные с&nbsp;целью предоставления Сервиса, включая:
            <ul>
              <li>обеспечение функционирования Сервиса;</li>
              <li>улучшение качества и&nbsp;удобства использования Сервиса;</li>
              <li>создание и&nbsp;развитие существующих и&nbsp;новых продуктов Компании и&nbsp;ее&nbsp;партнеров;</li>
              <li>персонализация контента и&nbsp;иных предложений;</li>
              <li>получение Пользователем сообщений;;</li>
              <li>продвижение Ресурсов, продуктов и/или услуг Компании и&nbsp;ее&nbsp;партнеров;</li>
              <li>прием обращений Пользователей и&nbsp;предоставление ответов на&nbsp;указанные обращения;</li>
              <li>проведение статистических и&nbsp;иных исследований использования Сервиса.</li>
            </ul>
          </li>
          <p>В&nbsp;указанной цели Компания обрабатывает следующие Данные: адрес электронной почты; сведения, собираемые посредством метрических программ; сведения о&nbsp;пользовательском устройстве; cookies, идентификатор пользователя, данные об&nbsp;использовании ресурса; сетевой адрес.</p>
          <li>Для цели, указанной в&nbsp;п.&nbsp;3.1. Политики, применимы следующие условия обработки:
            <ol>
              <li>Компанией не&nbsp;обрабатываются биометрические и&nbsp;специальные категории персональных данных Пользователей. Обрабатываемые Компанией персональные данные относятся к&nbsp;иным категориям персональных данных.</li>
              <li>Перечень действий с&nbsp;Данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), обезличивание, блокирование, удаление и&nbsp;уничтожение. Указанные действия могут быть осуществлены как неавтоматизированным, так и&nbsp;автоматизированным способами.</li>
              <li>Срок обработки и&nbsp;хранения Данных: до&nbsp;достижения цели обработки, до&nbsp;истечения срока действия согласия или до&nbsp;отзыва согласия (если отсутствуют иные основания для обработки таких Данных), в&nbsp;зависимости от&nbsp;того, какое событие наступило раньше.</li>
            </ol>
          </li>
        </ol>
        <p>3.3. Компания обязуется обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных Пользователей с использованием баз данных, находящихся на территории РФ.</p>
        <p>3.4. Для предоставления соответствующего Сервиса Пользователю Компания при наличии правового основания может осуществлять передачу (предоставление, доступ) Данных для их последующей обработки провайдерам услуг и партнёрам, указанным в соответствующих документах Компании, размещённых на Ресурсе.</p>
      </li>
      <li>
        <h3 className={styles.subtitle}>Основания обработки</h3>
        <ol>
          <li>Правовыми основаниями обработки Данных Компанией являются в&nbsp;том числе:
            <ul>
              <li>согласие Пользователя;</li>
              <li>исполнение, а&nbsp;также заключение Условий использования или иных соглашений с&nbsp;Пользователем;</li>
              <li>осуществление прав и&nbsp;законных интересов Компании или третьих лиц, если при этом не&nbsp;нарушаются права и&nbsp;свободы Пользователя;</li>
              <li>обработка в&nbsp;статистических или иных исследовательских целях, при условии обязательного обезличивания Данных;;</li>
              <li>необходимость обработки для достижения целей, предусмотренных законом, а&nbsp;также выполнение обязанностей, возложенных на&nbsp;Компанию законодательством.</li>
            </ul>
          </li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Права пользователя</h3>
        <ol>
          <p>Пользователь вправе:</p>
          <ul>
            <li>получать информацию, касающуюся обработки своих Данных;</li>
            <li>требовать от&nbsp;Компании уточнения его Данных, их&nbsp;блокирования или уничтожения в&nbsp;случае, если они неполные, устаревшие, неточные, незаконно полученные или не&nbsp;являются необходимыми для заявленной цели обработки;</li>
            <li>отозвать согласие на&nbsp;обработку своих Данных, направив Компании заявление</li>
          </ul>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Условия использования cookies</h3>
        <ol>
          <li>Для исполнения Условий использования и&nbsp;в&nbsp;рамках цели, указанной в&nbsp;п.&nbsp;3.1. Политики, Компания осуществляет обработку сookies в&nbsp;том числе для обеспечения функционирования Сервиса; персонализации Контента и&nbsp;иных предложений; проведения статистических и&nbsp;иных исследований использования Сервиса. Пользователь может просмотреть срок действия cookies, а&nbsp;также запретить их&nbsp;сохранение в&nbsp;настройках своего браузера. </li>
          <li>Сookies и&nbsp;иные сведения могут обрабатываться в&nbsp;том числе с&nbsp;использованием метрических программ, в&nbsp;частности, Статистика от&nbsp;SberAds, Яндекс.Метрика, Рейтинг Mail.ru, LiveInternet, Mediascope.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Порядок уничтожения</h3>
        <ol>
          <li>В&nbsp;отношении Данных, обрабатываемых Компанией в&nbsp;указанной в&nbsp;п.&nbsp;3.1. Политики цели, действует единый порядок их&nbsp;уничтожения в&nbsp;соответствии с&nbsp;законодательством&nbsp;РФ и&nbsp;локальными нормативными актами Компании.</li>
          <li>Данные подлежат уничтожению Компанией, в&nbsp;частности, если достигнута цель обработки или Пользователем отозвано согласие на&nbsp;их&nbsp;обработку (и&nbsp;отсутствуют иные основания для обработки таких Данных), либо выявлен факт их&nbsp;неправомерной обработки.</li>
          <li>В&nbsp;случае достижения цели обработки Данных Компания прекращает их&nbsp;обработку и&nbsp;уничтожает их&nbsp;в&nbsp;срок, не&nbsp;превышающий 30&nbsp;дней с&nbsp;даты достижения цели обработки. В&nbsp;случае отзыва Пользователем согласия на&nbsp;обработку его Данных, Компания прекращает их&nbsp;обработку и&nbsp;в&nbsp;случае, если сохранение Данных более не&nbsp;требуется для цели обработки, уничтожает их&nbsp;в&nbsp;срок, не&nbsp;превышающий 30&nbsp;дней с&nbsp;даты поступления указанного отзыва.</li>
          <li>В&nbsp;случае выявления неправомерной обработки персональных данных, осуществляемой Компанией, Компания в&nbsp;срок, не&nbsp;превышающий 3&nbsp;рабочих дней с&nbsp;даты этого выявления, прекращает такую обработку. В&nbsp;случае, если обеспечить правомерность такой обработки невозможно, Компания в&nbsp;срок, не&nbsp;превышающий 10&nbsp;рабочих дней с&nbsp;даты выявления неправомерной обработки, уничтожает такие Данные. При этом Компания уведомляет Пользователя об&nbsp;устранении допущенных нарушений или об&nbsp;уничтожении Данных.</li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Сведения о реализуемых требованиях к защите</h3>
        <ol>
          <li>Компания обязуется обеспечивать конфиденциальность персональных данных Пользователей.</li>
          <li>
            <p>Компания обеспечивает безопасность персональных данных Пользователей посредством реализации правовых, организационных и технических мер..</p>
            <ol>
              <li>
                <p>Правовые меры, принимаемые Компанией, включают:</p>
                <ul>
                  <li>издание Компанией документов, определяющих политику в&nbsp;отношении обработки персональных данных, локальных актов по&nbsp;вопросам обработки персональных данных, а&nbsp;также локальных актов, устанавливающих процедуры, направленные на&nbsp;предотвращение и&nbsp;выявление нарушений законодательства&nbsp;РФ, устранение последствий таких нарушений.</li>
                </ul>
              </li>
              <li>
                <p>Организационные меры, принимаемые Компанией, включают:</p>
                <ul>
                  <li>обеспечение неограниченного доступа к&nbsp;Политике, к&nbsp;сведениям о&nbsp;реализуемых требованиях к&nbsp;защите персональных данных;</li>
                  <li>осуществление внутреннего контроля&nbsp;и (или) аудита соответствия обработки персональных данных Федеральному закону от&nbsp;27.07.2006&nbsp;N 152-ФЗ &laquo;О&nbsp;персональных данных&raquo; (Закон) и&nbsp;принятым в&nbsp;соответствии с&nbsp;ним нормативным правовым актам, требованиям к&nbsp;защите персональных данных, политике Компании в&nbsp;отношении обработки персональных данных, локальным актам Компании;</li>
                  <li>оценка вреда, который может быть причинен субъектам персональных данных в&nbsp;случае нарушения Закона, соотношение указанного вреда и&nbsp;принимаемых Компанией мер, направленных на&nbsp;обеспечение выполнения обязанностей, предусмотренных Законом;</li>
                  <li>ознакомление работников Компании, непосредственно осуществляющих обработку персональных данных, с&nbsp;положениями законодательства&nbsp;РФ о&nbsp;персональных данных, в&nbsp;том числе требованиями к&nbsp;защите персональных данных, документами, определяющими политику Компании в&nbsp;отношении обработки персональных данных, локальными актами по&nbsp;вопросам обработки персональных данных, и&nbsp;(или) обучение указанных работников;</li>
                  <li>назначение лица, ответственного за&nbsp;организацию обработки персональных данных;</li>
                  <li>назначение лица, ответственного за&nbsp;обеспечение безопасности персональных данных в&nbsp;информационных системах персональных данных (ИСПДн);</li>
                  <li>утверждение руководителем Компании документа, определяющего перечень лиц, доступ которых к&nbsp;персональным данным, обрабатываемым в&nbsp;ИСПДн, необходим для выполнения ими служебных (трудовых) обязанностей;</li>
                  <li>обеспечение сохранности носителей персональных данных;</li>
                  <li>учет машинных носителей персональных данных;</li>
                  <li>определение угроз безопасности персональных данных при их&nbsp;обработке в&nbsp;ИСПДн;</li>
                  <li>организация контролируемой зоны, в&nbsp;пределах которой постоянно размещаются стационарные технические средства, обрабатывающие информацию, и&nbsp;средства защиты информации, а&nbsp;также средства обеспечения функционирования;</li>
                  <li>организация режима обеспечения безопасности помещений, в&nbsp;которых размещена ИСПДн, препятствующего возможности неконтролируемого проникновения или пребывания в&nbsp;этих помещениях лиц, не&nbsp;имеющих права доступа в&nbsp;эти помещения.</li>
                </ul>
              </li>
              <li>
                <p>Технические меры, принимаемые Компанией, включают:</p>
                <ul>
                  <li>использование средств защиты информации, прошедших процедуру оценки соответствия требованиям законодательства&nbsp;РФ в&nbsp;области обеспечения безопасности информации, в&nbsp;случае, когда применение таких средств необходимо для нейтрализации актуальных угроз;</li>
                  <li>оценку эффективности принимаемых мер по&nbsp;обеспечению безопасности персональных данных до&nbsp;ввода в&nbsp;эксплуатацию ИСПДн;</li>
                  <li>установление правил доступа к&nbsp;персональным данным, обрабатываемым в&nbsp;ИСПДн, а&nbsp;также обеспечением регистрации и&nbsp;учета всех действий, совершаемых с&nbsp;персональными данными в&nbsp;ИСПДн;</li>
                  <li>обнаружение фактов несанкционированного доступа к&nbsp;персональным данным и&nbsp;принятием мер, в&nbsp;том числе мер по&nbsp;обнаружению, предупреждению и&nbsp;ликвидации последствий компьютерных атак на&nbsp;ИСПДн и&nbsp;по&nbsp;реагированию на&nbsp;компьютерные инциденты в&nbsp;них;</li>
                  <li>восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к&nbsp;ним;</li>
                  <li>контроль за&nbsp;принимаемыми мерами по&nbsp;обеспечению безопасности персональных данных и&nbsp;уровня защищенности ИСПДн.</li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <h3 className={styles.subtitle}>Иное</h3>
        <ol>
          <li>Пользователь несет ответственность за&nbsp;правомерность предоставления, актуальность и&nbsp;достоверность данных Пользователя и&nbsp;иных лиц, которые Пользователь предоставляет Компании.</li>
          <li>Компания рассматривает обращения, связанные с&nbsp;Политикой, включая запросы Пользователей относительно использования их&nbsp;Данных по&nbsp;адресу:<i> 117105, Россия, г. Москва, Варшавское шоссе, д.&nbsp;9, стр.&nbsp;1.</i></li>
        </ol>
      </li>
    </ol>
    <p className={styles.editorial}><i>Редакция от 6 декабря 2024</i></p>
  </>
  );
}
