import { useEffect } from 'react';
import FullWidth from 'core/components/FullWidth';

import main from './js/main.js';

function MediaPage() {
  useEffect(() => {
    main();
  }, []);

  const textActivecode = 'Для активации подписки вам нужно будет авторизоваться на сайте или в платежном приложении Сбера после нажатия кнопки. Это безопасно. Звук не хранит ваши данные';

  return (
    <FullWidth>
      <div className='docSlider'>
        <section id='one'>
          <div className='wrap'>
            <div className='header'>
              <div className='logo' />
            </div>
            <div className='container'>
              <div className='l-block'>
                <div className='l-title'>Слушайте Музыку<br />
                  <span className='title-c'>Без интернета</span>
                </div>
                <div className='l-range'>
                  <div className='r-text'>
                    <p>30 дней бесплатно</p>
                    <p>Первое списание <span id='day' /></p>
                  </div>
                  <img src='media/images/range.svg' alt='' />
                  <div className='r-d-text'>
                    <p>Напомним о списании за 3 дня</p>
                  </div>
                </div>
                <div className='l-btn' data-count='1'>30 дней бесплатно</div>
                <div className='l-d-btn'>
                  <p>далее 199 ₽ в месяц</p>
                  <p className='activecode'>{textActivecode}</p>
                </div>
              </div>
              <div className='r-block'>
                <img src='media/images/phone.png' alt='' />
              </div>
            </div>
            <div className='container-d'>
              <div className='down'>
                <p>Подробнее о подписке</p>
                <a href='#two'><span /></a>
              </div>
            </div>
          </div>
        </section>
        <section id='two'>
          <div className='wrap'>
            <div className='header'>
              <div className='logo' />
            </div>
            <div className='container'>
              <div className='l-block'>
                <div className='l-title'>Новое удобное<br />
                  <span className='title-c'>Музыкальное</span><br />
                  Приложение
                </div>
                <div className='l-btn' data-count='2'>30 дней бесплатно</div>
                <div className='l-d-btn'>
                  <p>далее 199 ₽ в месяц</p>
                  <p className='activecode'>{textActivecode}</p>

                </div>
              </div>
              <div className='r-block'>
                <img src='media/images/screens.png' alt='' />
              </div>
            </div>
            <div className='container-d'>
              <div className='down'>
                <a href='#three'><span /></a>
              </div>
            </div>
          </div>
        </section>
        <section id='three'>
          <div className='wrap'>
            <div className='header'>
              <div className='logo' />
            </div>
            <div className='container'>
              <div className='l-block'>
                <div className='l-title'><span className='title-c'>Что еще</span><br />
                  в подписке
                  <div className='okko' />
                  <p className='three-t'>Онлайн-кинотеатр Окко</p>
                  <p className='three-s'>Смотрите тысячи фильмов и сериалов<br />
                    в высоком качестве и без рекламы.</p>
                  <div className='market' />
                  <p className='three-t'>Доставка продуктов из любимых магазинов</p>
                  <p className='three-s'>Доверьте покупки профессионалам: сотрудники СберМаркета<br />
                    соберут ваш заказ и доставят тяжелые сумки прямо до двери.
                    <br />
                    До 31 июля <span>бесплатная доставка.</span></p>
                </div>
                <div className='l-btn' data-count='3'>30 дней бесплатно</div>
                <div className='l-d-btn'>
                  <p>далее 199 ₽ в месяц</p>
                  <p className='activecode'>{textActivecode}</p>
                </div>
              </div>
              <div className='r-block'>
                <img src='media/images/scr.png' alt='' />
              </div>
            </div>
            <div className='container-d'>
              <div className='down'>
                <a href='#four'><span /></a>
              </div>
            </div>
          </div>
        </section>
        <section id='four'>
          <div className='wrap'>
            <div className='header'>
              <div className='logo' />
            </div>
            <div className='container'>
              <p className='title'>Скачивайте приложение</p>
              <p className='sub-title'>Чтобы слушать музыку где угодно</p>
              <div className='qr' />
              <div className='bubble' />
              <div className='store'>
                <div className='app' />
                <div className='gp' />
              </div>
            </div>
            <div className='container-d'>
              <div className='down'>
                <a href='#five'><span /></a>
              </div>
            </div>
          </div>
        </section>
        <section id='five'>
          <div className='wrap'>
            <div className='header'>
              <div className='logo' />
            </div>
            <div className='container'>
              <p className='l-title'>Ответы<br />
                <span className='title-c'>на частые вопросы:</span>
              </p>
              <div className='accr'>
                <button className='accordion'>Как оформить подписку</button>
                <div className='panel'>
                  <p>1. Нажмите кнопку «Оформить подписку» и войдите на сайт по Сбер ID. Если у вас ещё нет Сбер ID, вы
                    сможете создать его в процессе оформления подписки — это займёт всего пару минут.<br />
                    2. Ознакомьтесь с офертой и условиями предоставления сервисов подписки.<br />
                    3. Оплатите СберПрайм и подтвердите карту, с которой будет списываться плата за подписку.</p>
                </div>

                <button className='accordion'>Что такое Сбер ID?</button>
                <div className='panel'>
                  <p>Сбер ID — это единый вход в сервисы Сбера и партнеров. Если вы пользуетесь сайтом или приложением
                    СберБанк Онлайн, то у вас уже есть Сбер ID. Если нет — вы можете создать Сбер ID на этом сайте при
                    оформлении подписки.</p>
                </div>

                <button className='accordion'>Как изменить платежную карту?</button>
                <div className='panel'>
                  <p>Нажмите на своё имя в правом верхнем углу экрана и выберите раздел «Управление подпиской». Нажмите на
                    номер своей карты в блоке «Автопродление подписки» — мы предложим вам ввести данные новой карты.</p>
                </div>
                <button className='accordion'>Как отменить автопродление подписки?</button>
                <div className='panel'>
                  <p>Нажмите на своё имя в правом верхнем углу экрана и выберите раздел «Управление подпиской». Отключите
                    автопродление, нажав на кнопку в блоке «Автопродление подписки»</p>
                </div>
                <button className='accordion'>Действует ли подписка и сервисы в подписке на всей территории РФ?</button>
                <div className='panel'>
                  <p>Оформить подписку можно в любой точке страны, но некоторые сервисы подписки работают не во всех городах
                    России.</p>
                </div>
                <button className='accordion'>Как узнать, до какого числа оплачена подписка?</button>
                <div className='panel'>
                  <p>Нажмите на своё имя в правом верхнем углу экрана и выберите раздел «Управление подпиской». В блоке
                    «Подписка активна» вы увидите дату окончания оплаченного периода или дату автопродления подписки.</p>
                </div>
              </div>
              <div className='media-footer'>
                <div className='media-footer-t'>Оформляя подписку, вы подтверждаете, что ознакомились и согласны с&nbsp;
                  <a
                    target='_blank'
                    rel='nofollow noopener'
                    href='https://www.sberbank.com/sberprime/static/offers/offer.pdf'
                  >
                    условиями подписки
                  </a>
                </div>
                <div className='media-footer-d'>© 2017–2023, ООО «Звук» является аккредитованной ИТ-компанией и осуществляет свою
                  деятельность согласно ОКВЭД 62.01: разработка программного обеспечения. Сервис может содержать контент,
                  не предназначенный для несовершеннолетних (18+)</div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <style jsx global>{`
        @font-face{
            font-family:SBSansUI-Light;
            src:url(${require('./fonts/SBSansUI-Light.ttf')})
        }
        @font-face{
            font-family:SBSansUI-Regular;
            src:url(${require('./fonts/SBSansUI-Regular.ttf')})
        }
        @font-face{
            font-family:SBSansUI-Bold;
            src:url(${require('./fonts/SBSansUI-Bold.ttf')})
        }
        .docSlider {
          background-color: #2296E5;
        }
        .docSlider-button {
          width: 14px;
        }
        #one {
          background: url('media/images/bg_one.png') no-repeat;
          z-index: 2;
          padding-top: 50px;
          position relative
        }
        body.desktop #one:before {
          content ''
          position absolute
          top 0
          left 0
          right 0
          height 100px
          background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(34,150,229,0.5) 50%, rgba(34,150,229,0) 100%);
          z-index 0
        }
        #two {
          background: url('media/images/bg_two.png') no-repeat;
          z-index: 2;
        }
        #three {
          background: url('media/images/bg_three.png') no-repeat;
          z-index: 2;
        }
        #four {
          background: url('media/images/bg_three.png') no-repeat;
          z-index: 2;
        }
        #five {
          background: url('media/images/bg_three.png') no-repeat;
          z-index: 2;
        }
        .container {
          max-width: 1030px;
          margin: auto;
          margin-top: 70px;
          position: relative;
        }
        .container-d {
          max-width: 1030px;
          margin: auto;
          margin-top: 80px;
          position: relative;
        }
        .wrap {
          padding: 34px 25px;
          overflow-x: hidden;
        }
        .header {
          margin: 0 auto;
          max-width: 1030px;
          position: relative;
        }
        .logo {
          width: 100%;
          height: 53px;
          background: url('media/images/logo.svg') no-repeat;
          background-size: contain;
        }
        .okko {
          width: 100%;
          max-width: 70px;
          height: 25px;
          background: url('media/images/okko.svg') no-repeat;
          margin-top: 20px;
        }
        .market {
          width: 100%;
          max-width: 190px;
          height: 25px;
          background: url('media/images/market.svg') no-repeat;
          margin-top: 20px;
        }
        .l-title {
          font-family: SBSansUI-Bold;
          font-size: 60px;
          font-size: 50px;
          text-transform: uppercase;
          white-space: pre-line;
          color: #fff;
          letter-spacing: -1px;
          line-height: 1.1;
        }
        .l-title span {
          color: #007899;
        }
        .r-block {
          position: absolute;
          top: -70px;
          left: 67%;
          width: 100%;
        }
        .r-block img {
          width: 100%;
          height: auto;
          width: 100%;
          max-width: 287px;
          height: auto;
          border-radius: 35px;
          -webkit-box-shadow: 1px 1px 29px 9px rgba(255, 255, 255, 0.5);
          box-shadow: 1px 1px 29px 9px rgba(255, 255, 255, 0.5);
        }
        .r-text {
          font-family: SBSansUI-Light;
          font-size: 16px;
          color: #FFFFFF;
          margin: 40px 15px 10px 15px;
          display: flex;
          justify-content: space-between;
          max-width: 370px;
          letter-spacing: -0.5px;
        }
        .r-d-text {
          font-family: SBSansUI-Light;
          font-size: 16px;
          color: #FFFFFF;
          margin: 0px;
          display: flex;
          justify-content: center;
          max-width: 400px;
          letter-spacing: -0.5px;
        }
        .three-t {
          font-family: 'SBSansUI-Light';
          font-size: 24px;
          text-transform: none;
          letter-spacing: 0px;
          padding: 12px 0px;
        }
        .three-s {
          font-family: 'SBSansUI-Light';
          font-size: 16px;
          text-transform: none;
          letter-spacing: 0px;
        }
        .l-btn {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 32px;
          width: 400px;
          height: 99px;
          background: linear-gradient(86.45deg, #FFFFFF -29.49%, #00A4D1 24.95%, #052933 111.74%);
          border-radius: 16px;
          font-family: 'SBSansUI-Regular';
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 110%;
          text-align: center;
          color: #FFFFFF;
          margin: 20px 0px;
          cursor: pointer
        }
        .l-btn:active {
          background: linear-gradient(69.99deg, #007899 -14.39%, #073C4B 118.14%);
        }
        .l-d-btn {
          font-family: SBSansUI-Light;
          font-size: 20px;
          color: #FFFFFF;
          margin: 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 400px;
          letter-spacing: -0.5px;
          align-items: center;
        }
        .l-d-btn p {
          margin-bottom: 15px;
        }
        .down {
          font-family: 'SBSansUI-Regular';
          font-size: 24px;
          color: #FFFFFF;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
        }
        .down a {
          padding-top: 30px;
        }
        .down a span {
          width: 56px;
          height: 20px;
          background: url('media/images/arrow.svg') no-repeat;
          -webkit-animation: sdb04 2.5s infinite;
          animation: sdb04 2.5s infinite;
          box-sizing: border-box;
          display: block;
        }
        @-webkit-keyframes sdb04 {
          0% {
            -webkit-transform: translate(0, 0);
          }
          20% {
            -webkit-transform: translate(0px, 10px);
          }
          40% {
            -webkit-transform: translate(0, 0);
          }
        }
        @keyframes sdb04 {
          0% {
            transform: translate(0, 0);
          }
          20% {
            transform: translate(0px, 10px);
          }
          40% {
            transform: translate(0, 0);
          }
        }
        textarea:focus, input:focus, label:focus {
          outline: none;
        }
        input:focus+.slider {
          outline: none;
        }
        #two .r-block img {
          width: 100%;
          max-width: 565px;
          height: auto;
          border-radius: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        #three .r-block img {
          width: 100%;
          max-width: 564px;
          height: auto;
          border-radius: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        #two .r-block {
          position: absolute;
          top: -35px;
          left: 50%;
          width: 100%;
        }
        #three .r-block {
          position: absolute;
          top: -15px;
          left: 52%;
          width: 100%;
        }
        #two .container {
          margin-top: 134px;
        }
        #three .container {
          margin-top: 60px;
        }
        #three .l-btn {
          margin-top: 40px;
        }
        #two .l-title {
          margin-bottom: 40px;
        }
        #two .container-d {
          margin-top: 100px;
        }
        #two .container-d {
          margin-top: 50px;
        }
        #three .l-title {
          white-space: normal;
        }
        .qr {
          width: 190px;
          height: 190px;
          background: url('media/images/qr.svg') no-repeat;
          margin: 32px auto;
          margin-bottom: 0;
          background-size: contain;
        }
        .bubble {
          width: 269px;
          height: 92px;
          background: url('media/images/bubble.svg');
          margin: 14px auto;
        }
        .store {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }
        .app {
          width: 200px;
          height: 60px;
          background: url('media/images/Appstore.svg');
          margin: 15px 12px 15px 12px;
          cursor: pointer;
        }
        .gp {
          width: 200px;
          height: 60px;
          background: url('media/images/GP.svg');
          margin: 15px 12px 15px 12px;
          cursor: pointer;
        }
        .title {
          font-family: SBSansUI-Bold;
          text-align: center;
          font-size: 50px;
          text-transform: uppercase;
          color: #fff;
          letter-spacing: -1px;
          line-height: 1.1;
        }
        .sub-title {
          font-family: 'SBSansUI-Light';
          text-align: center;
          font-size: 32px;
          color: #fff;
          letter-spacing: -1px;
          line-height: 1.1;
          margin-top: 15px;
        }
        .accordion {
          background-color: transparent;
          color: #fff;
          font-family: 'SBSansUI-Light';
          font-size: 20px;
          cursor: pointer;
          padding: 18px 0px;
          width: 100%;
          text-align: left;
          border: none;
          outline: none;
          transition: 0.4s;
        }
        /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
        .active {
          padding: 18px 0px 8px 0px;
        }
        .panel {
          font-family: 'SBSansUI-Regular';
          padding: 0px;
          border-bottom: 1px solid #fff;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-out;
          font-size: 14px;
          color: #007899;
        }
        .accordion:after {
          content: url('media/images/plus.svg');
          height: 24px;
          width: 24px;
          float: right;
          margin-left: 5px;
        }
        .active:after {
          content: url('media/images/minus.svg');
        }
        .accr {
          margin-top: 45px;
        }
        .media-footer {
          margin-top: 80px;
          font-family: 'SBSansUI-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #007899;
        }
        .media-footer-t {
          margin-bottom: 10px;
          text-align: center;
        }
        @media (max-width: 1010px) {
          #three .r-block img {
            max-width: 470px;
          }
          #three .r-block {
            position: absolute;
            top: 10%;
            left: 58%;
          }
        }
        @media (max-width: 810px) {
          .media-footer {
            text-align: center;
          }
          .docSlider-pager {
            display: none;
          }
          #one {
            background: url('media/images/bg_m.jpg') no-repeat center;
            background-size: cover;
            box-shadow: 0px -9px 116px 72px rgba(0, 0, 0, 0.65);
            -webkit-box-shadow: 0px -9px 116px 72px rgba(0, 0, 0, 0.65);
            -moz-box-shadow: 0px -9px 116px 72px rgba(0, 0, 0, 0.65);
            background-position: bottom;
          }
          .r-block {
            display: none;
          }
          #one .r-text p, #one .r-d-text {
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
          }
          #two .r-block, #three .r-block {
            display: flex;
            justify-content: center;
          }
          #two .header, #three .header, #five .header {
            display: none;
          }
          #two .container, #three .container {
            margin-top: 0px;
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
          }
          #three .l-block {
            margin-top: 40px;
          }
          .okko, .market {
            margin: 20px auto 0px;
          }
          #three .l-btn {
            margin: 40px auto 20px;
          }
          #three .l-d-btn {
            margin: 20px auto;
          }
          #two .r-block, #three .r-block {
            position: initial;
            z-index: 1;
            top: -35px;
            left: 50%;
            width: 100%;
          }
          .wrap {
            margin-top: 40px;
          }
          #one .container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .l-range {
            margin-top: 350px;
          }
          .logo {
            width: 100%;
            max-width: 300px;
            height: 50px;
            background: url('media/images/logo.svg') no-repeat center;
            background-size: contain;
          }
          .l-title {
            text-align: center;
            font-size: 40px;
          }
          .l-title span {
            color: #fff;
          }
          #two .title-c, #three .title-c {
            color: #007899;
          }
          .container-d {
            display: none;
          }
          .header {
            display: flex;
            justify-content: center;
          }
          #five .l-title span {
            color: #007899;
          }
          .activecode {
            text-align: center;
          }
        }
        @media (max-width: 520px) {
          #one {
            background-position-y: 20px;
            background: url('media/images/bg_m.jpg') no-repeat;
            background-size: contain;
            box-shadow: 0px -9px 116px 72px rgba(0, 0, 0, 0.65);
            -webkit-box-shadow: 0px -9px 116px 72px rgba(0, 0, 0, 0.65);
            -moz-box-shadow: 0px -9px 116px 72px rgba(0, 0, 0, 0.65);
            background-color: #0b0b0b;
          }
          .l-range {
            margin-top: 230px;
          }
          #one .wrap {
            margin-top: 5px;
          }
          .qr, .bubble {
            display: none;
          }
          .store {
            flex-direction: column;
            align-items: center;
          }
          .l-btn {
            margin: 20px auto;
            max-width: 335px;
            font-size: 24px;
            height: 74px;
            padding: 12px;
            justify-content: center;
          }
          .sub-title {
            font-size: 24px;
          }
          .title {
            font-size: 40px;
          }
          .accordion {
            font-size: 16px;
          }
        }
        @media (max-width: 420px) {
          .l-range img {
            margin: 0 auto;
            max-width: 300px;
            display: block;
          }
          .r-text, .r-d-text {
            font-size: 14px;
          }
          .l-d-btn {
            font-size: 16px;
          }
        }
      `}
      </style>
    </FullWidth>
  );
}

export default MediaPage;
