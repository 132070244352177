import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { Desktop, Mobile, withBreakpoint  } from 'core/components/breakpoint';

import Feed from 'core/components/Feed';
import Scroller from 'core/components/Scroller';
import EmptyWrapper from 'core/components/EmptyWrapper';
import H2 from 'core/components/H2';
import H3 from 'core/components/H3';
import { Indent } from 'core/components/Wrappers';

import { CardStarM, CardStarS } from 'site/cards/CardStar';

import styles from './index.styl';


function SimilarStars(props) {
  const {
    topics,
    isMobile,
  } = props;

  if (!topics?.length) return null;

  const randomizedTopics = topics.sort(() => Math.random() - 0.5).slice(0, 4);

  const HeadlineWrapper = isMobile ? H3 : H2;

  return (
    <EmptyWrapper>
      <HeadlineWrapper is='h2'>Еще вам может понравиться</HeadlineWrapper>
      <Indent top={20} />
      <Desktop>
        <Feed
          content={randomizedTopics}
          card={CardStarM}
          interitemSpacing={100}
          columns={randomizedTopics.length}
          grid
        />
      </Desktop>
      <Mobile>
        <Indent right={-20}>
          <Scroller>
            {randomizedTopics.map(topic => (
              <div className={styles.card} key={topic.id}>
                <CardStarS content={topic} />
              </div>
            ))}
          </Scroller>
        </Indent>
      </Mobile>
    </EmptyWrapper>
  );
}

SimilarStars.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SimilarStars);
