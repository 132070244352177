import { useContext } from 'react';

import CoreLink from 'core/components/Link';

import EmbedContext from 'site/components/Embed';

export default function Link(props) {
  const embedMode = useContext(EmbedContext);

  return <CoreLink {...embedMode && { target: '_blank' }} {...props} />;
}
