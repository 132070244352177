export default function getGeo(tags) {
  if (!tags || !tags.length) return null;

  return tags.reduce((acc, currentTag, currentIndex, arr) => {
    const rootSlug = currentTag.attributes.root_slug;

    if (rootSlug) return acc;

    currentTag.incoming = arr.filter(tag => tag.attributes.root_slug === currentTag.attributes.slug);
    return [...acc, currentTag];
  }, []);
}
