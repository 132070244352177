import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import PageLoader from 'core/components/Loader/PageLoader';

import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { PageIndent, Indent } from 'core/components/Wrappers';
import snippet from 'core/components/TopicContent/blocks/snippet';

import { NegativeMobile } from 'site/components/Wrappers';
import Popular from './Popular';
import Opinion from 'site/widgets/Opinion';
import MainGameCompare from './GameCompare';

import { Parallax } from 'site/components/Ad/desktop';
import { Listing } from 'site/components/Ad/mobile';

import CardVertical from 'site/cards/CardVertical';
import CardMainHero, { CardMainHero1, CardMainHero2, CardMainHero3 } from 'site/cards/CardMainHero';
import GalleryTopics from 'site/components/GalleryTopics';

import { getWidget } from 'site/utils';

import useMainTopics from './useMainTopics';

import styles from './index.styl';


function MainPage({ isMobile, history }) {
  const {
    popular,
    gallery,
    main,
    quote,
    playlist,
    clipped,
    unclipped,
    gameCompare,
    isLoading,
  } = useMainTopics({ history });

  if (isLoading) return <PageLoader />;

  const vertical = isMobile ? 10 : 20;
  const gotQuote = !!quote?.length;
  const gotPlaylist = !!playlist?.length;
  const musicList = gotPlaylist ? getWidget(playlist[0], 'snippet') : null;
  const opinion = gotQuote ? getWidget(quote[0], 'opinion') : null;
  const opinionLink = gotQuote ? quote[0].attributes.link : null;

  // eslint-disable-next-line react/prop-types
  const SupperAnnounce = ({ content, card }) => (
    <Indent top={vertical}>
      <Feed
        card={card}
        content={content}
      />
    </Indent>
  );

  return (
    <Page>
      <PageIndent>
        <SupperAnnounce
          content={clipped}
          card={isMobile ? CardMainHero3 : CardMainHero2}
        />
        <SupperAnnounce
          content={unclipped}
          card={isMobile ? CardMainHero1 : CardMainHero}
        />
        <Indent top={vertical}>
          <MainGameCompare topics={gameCompare} />
        </Indent>
        <Indent top={vertical}>
          <Feed
            content={main}
            card={CardVertical}
            interitemSpacing={20}
            columns={3}
            grid
          />
        </Indent>
        {gotQuote &&
          <Indent top={vertical}>
            <Opinion block={opinion} link={opinionLink} />
          </Indent>
        }
        <AdWrapper top={vertical}>
          <Desktop>
            <Parallax />
          </Desktop>
          <Mobile>
            <NegativeMobile>
              <Listing />
            </NegativeMobile>
          </Mobile>
        </AdWrapper>
        {(gallery &&  gallery.length > 0) &&
          <Fragment>
            <Desktop>
              <Indent top={vertical}>
                <div className={styles.galleryContainer}>
                  <div className={styles.galleryWrapper}>
                    <GalleryTopics topics={gallery} />
                  </div>
                </div>
              </Indent>
            </Desktop>
            <Mobile>
              <Indent top={vertical} />
              <NegativeMobile>
                <GalleryTopics topics={gallery} />
              </NegativeMobile>
            </Mobile>
          </Fragment>
        }
        <Indent top={vertical}>
          <Popular content={popular}  />
        </Indent>
        {musicList &&
          <Indent top={vertical}>
            {snippet(musicList)}
          </Indent>
        }
      </PageIndent>
    </Page>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default withBreakpoint(MainPage);
