import { useContext } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { Desktop, Mobile  } from 'core/components/breakpoint';

import Link from 'site/components/Link';
import Socializator from 'core/components/Socializator';
import H1 from 'core/components/H1';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { MaxWidthWrapper, Indent } from 'site/components/Wrappers';
import EmbedContext from 'site/components/Embed';

import { dateFormat } from 'core/utils/dates';
import pluralize from 'core/utils/pluralize';
import { denormalizeData } from 'core/utils/api';

import Play from 'site/icons/Play.svg';

import Media from './Media';
import Images from './Media/Images';

import { getFullYearsDelta, isStarTopic } from 'site/utils';

import styles from './index.styl';

import fieldsConfig from './fields';
import { SIDE_INDENT_MOBILE } from 'site/constants';

/*
  Рендерер для параграфа с дискографией. Нужен для отрисовки иконки при наличии ссылки
*/
const discographyRenderer = ({ children, node, embedMode }) => {
  const nodeChildWithLink = node.children.find(nodeChild => nodeChild.tagName === 'a');

  const url = nodeChildWithLink?.properties?.href;

  if (!url || embedMode) return <p className={styles.discographyParagraph}>{children}</p>;

  return (
    <p className={styles.paragraphWithLink}>
      <span className={styles.linkContent}>
        {children}
        <Link
          to={url}
          type='secondary'
          className={styles.paragraphIconLink}
          title='Послушать этот альбом'
        >
          <Play
            height={18}
            width={18}
          />
        </Link>
      </span>
    </p>
  );
};


function TopicHeaderStars({ content, theme }) {
  const embedMode = useContext(EmbedContext);

  const {
    attributes: {
      headline,
      topic_type: topicType,
    },
  } = content;

  const {
    artist_group: artistGroup,
    artist_person: artistPerson,
    tags,
  } = content.relationships;

  const normalizedTags = denormalizeData(tags);
  const artistData = (denormalizeData(artistGroup) || denormalizeData(artistPerson))?.attributes || {};
  const {
    birth_date: birthDateString,
    death_date: deathDateString,
  } = artistData;
  const isDead = !!deathDateString;
  const birthDate = birthDateString
    ? new Date(birthDateString)
    : null;
  const lastDate = isDead
    ? new Date(deathDateString)
    : new Date();
  const age = birthDate
    ? getFullYearsDelta(birthDate, lastDate)
    : null;
  const ageText = age
    ? age + ' ' + pluralize(age, ['год', 'года', 'лет'])
    : '';

  const Wrapper = isStarTopic(topicType)
    ? EmptyWrapper
    : MaxWidthWrapper;

  const ItemWrapper = embedMode ? EmptyWrapper : Link;

  return (
    <Wrapper>
      <style jsx>{`
        .${styles.content}
          background ${theme.colors.grey}
          :global(.mobile) &
            margin 0 -${SIDE_INDENT_MOBILE}px
            &:after
              background ${theme.colors.grey}
            td
              padding-left ${SIDE_INDENT_MOBILE}px
              padding-right ${SIDE_INDENT_MOBILE}px

        .${styles.rowWrapper}
          font 16px/20px ${theme.fonts.text}
          :global(.desktop) &
            border-bottom 1px solid ${theme.colors.divider}

        .${styles.title}
          :global(.mobile) &
            color ${theme.colors.gray40}

        .${styles.value}
          :global(.mobile) &
            border-bottom 1px solid ${theme.colors.divider}
      `}</style>
      <div className={styles.shapka}>
        <div className={styles.headline}>
          <H1>{headline}</H1>
        </div>
        {!embedMode && <Socializator />}
      </div>
      <Mobile>
        <Indent left={SIDE_INDENT_MOBILE} right={SIDE_INDENT_MOBILE}>
          <Images content={content} />
        </Indent>
      </Mobile>
      <div className={styles.content}>
        <table className={styles.table}>
          <tbody>
            {fieldsConfig.map(({ slug, name, isMarkdown }, index) => {
              const isDiscography = slug === 'discography';
              const isMusicGenre = slug === 'music_genre';
              const rawValue = isMusicGenre ? normalizedTags.length : artistData[slug];

              if (!rawValue) return null;

              let value = isMusicGenre
                ? normalizedTags.filter((el) => el.attributes.tag_type === 'genre')
                  .map((el, i, arr) => (
                    <ItemWrapper
                      key={el.id}
                      to={`/label/${el.attributes.slug}?type=stars`}
                    >
                      {el.attributes.title}{i !== arr.length - 1 ? ', ' : ''}
                    </ItemWrapper>
                  ))
                : rawValue;

              if (slug === 'birth_date') {
                value = `${dateFormat(rawValue, 'dd MMMM y')}${ageText && ` (${ageText})`}`;
              } else if (slug === 'death_date') {
                value = `${dateFormat(rawValue, 'dd MMMM y')}`;
              } else if (slug === 'zodiac_ru') {
                value = (
                  <span className={styles[slug]}>
                    {rawValue}
                  </span>
                );
              }

              const FieldWrapper = isMarkdown ? MarkdownWrapper : EmptyWrapper;

              const valueMarkup = (
                <FieldWrapper
                  {...isDiscography && {
                    components: {
                      p: (props) => discographyRenderer({ ...props, embedMode }),
                      ...embedMode && {
                        a: ({ children }) => children,
                      },
                    },
                  }}
                >
                  {value}
                </FieldWrapper>
              );

              return (
                <tr key={index} className={styles.rowWrapper}>
                  <td>
                    <div className={styles.title}>{name}</div>
                    <Mobile>
                      <div className={styles.value}>
                        {valueMarkup}
                      </div>
                    </Mobile>
                  </td>
                  <Desktop>
                    <td className={styles.valueWrapper}>
                      {valueMarkup}
                    </td>
                  </Desktop>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Media
          content={content}
        />
      </div>
    </Wrapper>
  );
}

TopicHeaderStars.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(TopicHeaderStars);
