export const QUERY_TYPE_MAP = {
  articles: {
    queryType: 'articles',
    requestType: '-news,artist_group,artist_person',
    withSearch: false,
  },
  stars: {
    queryType: 'stars',
    requestType: 'artist_group,artist_person',
    withSearch: true,
  },
  default: {
    queryType: 'news',
    requestType: 'news',
    withSearch: false,
  },
};
