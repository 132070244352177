import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';

import Link from 'site/components/Link';
import EmptyWrapper from 'core/components/EmptyWrapper';
import SmartImage from 'core/components/SmartImage';
import Button from 'core/components/Button';
import ShortWaves from 'site/icons/ShortWaves.svg';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
];

const requiredPayloadImports = [
  'image',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

const imageSize = 191;

function CardMain(props) {
  const {
    content,
    size,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: originalCover,
        top_announce: topAnnounceCover,
      },
    },
  } = relationships(content);

  const cover = topAnnounceCover || originalCover;
  const isLarge = size === 'm';
  const hasCover = !!cover;

  const Wrapper = size === 's' ? 'div' : EmptyWrapper;

  return (
    <div
      className={cx(
        styles.card,
        styles[`_size_${size}`],
        hasCover && '_hasCover',
      )}
    >
      <style jsx>{`
        .${styles.card}._hasCover
          min-height ${imageSize}px
        .${styles.content}
          color ${theme.colors.content}
        .headline
          font 16px/19px ${theme.fonts.display}
        .${styles.altHeadline}
          font 14px/18px ${theme.fonts.text}
        :global(button)
          .${styles.wrapper}:hover &
            background ${theme.colors.magenta60}
            border 1px solid ${theme.colors.magenta60}
        .${styles.none}
          color ${theme.colors.content}
      `}</style>
      <Link
        to={link}
        type='secondary'
      >
        <div className={styles.wrapper}>
          {hasCover && (
            <div className={styles.image}>
              <Wrapper className={styles.imageSmallWrapper}>
                <SmartImage
                  width={imageSize}
                  height={imageSize}
                  placement='contain'
                  position='50% 100%'
                  src={cover}
                  instant
                />
              </Wrapper>
            </div>
          )}
          <div className={styles.content}>
            <div className='headline'>
              {listHeadline || headline}
            </div>
            {altHeadline &&
              <div className={styles.altHeadline}>
                {altHeadline}
              </div>
            }
            {isLarge &&
              <div className={styles.btn}>
                <Button type='tertiary' size='small'>Посмотреть</Button>
              </div>
            }
          </div>
          <div className={styles.none}>
            <ShortWaves />
          </div>
        </div>
      </Link>
    </div>
  );
}

CardMain.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object.isRequired,
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm']),
};

CardMain.defaultProps = {
  size: 'm',
};

const Card = withTheme(CardMain);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as CardMainM };
export const CardMainS = bindProps({ size: 's' })(Card);

export default Card;

export { CardMain as StorybookComponent };
