import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';
import { withRouter } from 'core/libs/router';

import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';
import { isGameCompare } from 'core/components/GameCompare/utils';
import PageLoader from 'core/components/Loader/PageLoader';
import Feed from 'core/components/Feed';

import ListDivider from 'site/components/ListDivider';
import { Indent, NegativeMobile } from 'site/components/Wrappers';
import { Listing } from 'site/components/Ad/mobile';
import GameCompareRubric from './GameCompareRubric';

import CardSmall, { CardSmallM } from 'site/cards/CardSmall';
import CardVertical from 'site/cards/CardVertical';
import CardStory from 'site/cards/CardStory';
import CardMainHero from 'site/cards/CardMainHero';

const MobileCard = bindProps({
  card: CardSmall,
  indent: 10,
})(ListDivider);

const DesktopCard = bindProps({
  card: CardSmallM,
  indent: 20,
})(ListDivider);

const LabelCard = bindProps({ type: 2 })(CardVertical);

const NewsListing = () => (
  <Indent top={10}>
    <NegativeMobile ><Listing /></NegativeMobile>
  </Indent>
);


function RubricTopics(props) {
  const {
    topics,
    isDesktop,
    topicType,
    location,
    match: {
      params: {
        tagType,
      },
    },
    isLoading,
  } = props;

  const spacing = 20;
  const isGameComparePage = isGameCompare(location.pathname);

  if (isLoading) return <PageLoader />;

  if (isGameComparePage) {
    return <GameCompareRubric topics={topics} isDesktop={isDesktop} />;
  }

  if (topicType === 'news' && topics.length > 0) {
    if (tagType === 'label') {
      return (
        <Indent top={30} bottom={spacing}>
          <Feed
            interitemSpacing={spacing}
            content={topics}
            card={LabelCard}
            columns={3}
            grid
          />
        </Indent>
      );
    }

    if (isDesktop) {
      return (
        <Indent top={30} bottom={spacing}>
          <Feed
            content={topics}
            card={DesktopCard}
          />
        </Indent>
      );
    }

    const first = topics.slice(0, 6);
    const second = topics.slice(6);

    return (
      <Indent top={spacing} bottom={spacing}>
        <Feed
          content={first}
          card={MobileCard}
        />
        <NewsListing />
        <Feed
          content={second}
          card={MobileCard}
        />
      </Indent>
    );
  }

  const first = topics.slice(0, 1);
  const second = topics.slice(1, 4);
  const third = topics.slice(4, 8);
  const fourth = topics.slice(8);

  return (
    <Fragment>
      {first.length > 0 && (
        <Indent top={spacing}>
          <Feed
            interitemSpacing={spacing}
            card={isDesktop ? CardMainHero : CardVertical}
            content={first}
          />
        </Indent>
      )}
      {second.length > 0 && (
        <Indent top={spacing} >
          <Feed
            interitemSpacing={spacing}
            card={CardVertical}
            content={second}
            columns={3}
            grid
          />
        </Indent>
      )}
      {!isDesktop && (
        <NegativeMobile>
          <Indent top={spacing}>
            <Listing />
          </Indent>
        </NegativeMobile>
      )}
      {third.length > 0 && (
        <Indent top={spacing}>
          <Feed
            interitemSpacing={spacing}
            card={CardVertical}
            content={third}
            columns={4}
            grid
          />
        </Indent>
      )}
      {fourth.length > 0 && (
        <Indent top={spacing} >
          <Feed
            interitemSpacing={spacing}
            card={isDesktop ? CardStory : CardVertical}
            content={fourth}
            columns={2}
            grid
          />
        </Indent>
      )}
      <Indent bottom={spacing} />
    </Fragment>
  );
}

RubricTopics.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
  isDesktop: PropTypes.bool,
  topicType: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tagType: PropTypes.string,
    }),
  }),
  isLoading: PropTypes.bool,
};

export default withRouter(withBreakpoint(RubricTopics));
