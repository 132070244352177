import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawTopicsQuery } from 'core/queries/listPage';

import ListPage from 'core/components/ListPage';
import H1 from 'core/components/H1';
import Page from 'core/components/Page';

import { filterRequiredParams } from 'core/utils/api';

import RubricTopics from 'site/components/RubricTopics';
import { PageIndent, MaxWidthWrapper } from 'site/components/Wrappers';

import { NEWS_LIMIT } from 'site/constants';

import CardSmall from 'site/cards/CardSmall';


const fields = filterRequiredParams([CardSmall], 'fields');
const include = filterRequiredParams([CardSmall], 'include');


function NewsPage(props) {
  const { location, match, history } = props;

  const { data: rawData, isLoading: rawTopicsIsLoading } = useRequest(rawTopicsQuery({
    location,
    match,
    history,
    limit: NEWS_LIMIT,
    include: include,
    fields: fields,
    topic_type: 'news',
  }));

  return (
    <Page title='Последние новости мира музыки' description='Последние новости мира музыки! Каждый день наше СМИ работает, чтобы вы получали свежие новости из мира музыки и факты из жизни любимых артистов.' >
      <PageIndent>
        <MaxWidthWrapper>
          <H1>Новости</H1>
          <ListPage
            limit={NEWS_LIMIT}
            rawData={rawData}
            isLoading={rawTopicsIsLoading}
          >
            {({ content }) => (
              <RubricTopics
                topics={content}
                topicType='news'
                isLoading={rawTopicsIsLoading}
              />
            )}
          </ListPage>
        </MaxWidthWrapper>
      </PageIndent>
    </Page>
  );
}

NewsPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default NewsPage;
