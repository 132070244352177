import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';
import { topicsQuery } from 'core/queries/topics';

import resolveRelationships from 'core/utils/relationships';

import { denormalizeData } from 'core/utils/api';

import CardSmall from 'site/cards/CardSmall';

import { isStarTopic, getArtistsTag, getGenreTags } from 'site/utils';

const relationships = resolveRelationships(...[
  ['tags', 'content'],
  {},
  { tags: [], content: {} },
]);

export default function useTopicContent({ location, match, history, isDesktop }) {
  const [
    { data: content, isLoading: contentIsLoading },
    { data: newsFeed, isLoading: newsFeedIsLoading },
  ] = useRequest({
    queries: [
      topicQuery({ location, match, history }),
      topicsQuery({
        limit: isDesktop ? 9 : 5,
        include: 'tags',
        topic_type: 'news',
        card: CardSmall,
        renderError: error => `NewsFeed request failed: ${error}`,
        history,
      }, { select: denormalizeData }),
    ],
  });

  const isStar = isStarTopic(content?.attributes?.topic_type);
  const { tags } = relationships(content);

  const queries = isStar ? [
    topicsQuery({
      history,
      limit: 3,
      tag: getArtistsTag(tags)?.attributes.slug,
      topic_type: 'news',
      include: 'image,rubric,photo_gallery,tags',
      fields: 'headline,list_headline,alternative_headline,link,topic_type',
      sort: '-published_at',
    }, { select: denormalizeData }),
    topicsQuery({
      history,
      limit: 30,
      topic_type: 'artist_person,artist_group',
      include: 'image',
      fields: 'headline,link',
      excluded_ids: content?.id,
      tag: getGenreTags(tags),
    }, { select: denormalizeData }),
  ] : [];

  const results = useRequest({ queries });

  if (contentIsLoading || newsFeedIsLoading) return { isLoading: true };
  if (!isStar) return { content, newsFeed };

  const [
    { data: topicsWithStars, isLoading: topicsWithStarsIsLoading },
    { data: similarStars, isLoading: similarStarsIsLoading },
  ] = results;

  if (topicsWithStarsIsLoading || similarStarsIsLoading) {
    return {
      topicsWithStarsIsLoading,
      similarStarsIsLoading,
    };
  }

  return { content, newsFeed, topicsWithStars, similarStars };
}
