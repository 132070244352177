import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { authorQuery, topicsQuery } from 'core/queries/authorPage';

import AuthorPage from 'core/components/AuthorPage';

import { filterRequiredParams } from 'core/utils/api';

import { MaxWidthWrapper, PageIndent } from 'site/components/Wrappers';

import CardSmall from 'site/cards/CardSmall';
import CardAuthorPage  from 'site/cards/CardAuthor/AuthorPageCard';


function Author({ match, location, history }) {
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = useRequest({
    queries: [
      authorQuery({ match, history }),
      topicsQuery({
        location,
        match,
        limit: 15,
        topic_type: 'artist_person,artist_group,news,article',
        fields: filterRequiredParams([CardSmall], 'fields'),
      }),
    ],
  });

  return (
    <PageIndent>
      <MaxWidthWrapper>
        <AuthorPage
          author={author}
          topics={topics}
          card={CardSmall}
          interitemSpacing={20}
          authorCard={CardAuthorPage}
          titleBuilder={a => `${a.attributes.name}: редактор новостей медиакомпании`}
          descriptionBuilder={a => `${a.attributes.name} - редактор новостей медиа «Звук». Медиакомпания, которая с вами на одной волне. Каждый день мы работаем, чтобы вы получали свежие новости из мира музыки и интересные факты о жизни любимых артистов.`}
          isLoading={topicsAreLoading || authorIsLoading}
        />
      </MaxWidthWrapper>
    </PageIndent>
  );
}

Author.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Author;
