import PropTypes from 'prop-types';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';
import withTheme from 'core/components/theme';
import Link from 'site/components/Link';

import styles from './index.styl';

const requiredPayloadFields = [
  'name',
  'slug',
  'job_title',
];


function AuthorsPageCard(props) {
  const {
    content,
    author,
    theme: {
      colors: {
        primary,
        gray40,
      },
    },
  } = props;

  if (!(content || author)) return null;

  const {
    attributes: {
      name,
      slug,
      job_title: jobTitle,
    },
  } = content || author;


  return (
    <div className='authorCard'>
      <style jsx>{`
        .${styles.name}
          color ${primary}

        .${styles.job}
          color ${gray40}
        `}</style>

      <Link to={`/authors/${slug}`} type='secondary'>
        <div className={styles.name}>{name}</div>
        {!!jobTitle && <div className={styles.job}>{jobTitle}</div>}
      </Link>
    </div>
  );
}

AuthorsPageCard.propTypes = {
  /** Данные автора, соответствующие модели `person` */
  content: modelPropTypes(personAttributes),
  /** Данные автора, соответствующие модели `person` */
  author: modelPropTypes(personAttributes),
  /** @ignore */
  theme: PropTypes.object,
};


AuthorsPageCard.requiredPayloadFields = requiredPayloadFields;

const CardWithHOCs = withTheme(AuthorsPageCard);
CardWithHOCs.displayName = 'AuthorsPageCard';

export { AuthorsPageCard as StorybookComponent };
export default CardWithHOCs;
