import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { Block, Section } from 'core/components/Grid';
import SearchInput from 'core/components/SearchInput';
import Button from 'core/components/Button';
import withTheme from 'core/components/theme';
import { Indent } from 'core/components/Wrappers';

import Noindex from 'site/components/Noindex';

import TopicsList from './TopicsList';
import styles from './index.styl';
import Filters from './Filters';


const size = 'small';

class ListPageWithFilters extends Component {
  state = {
    inputValue: '',
  };

  componentDidMount() {
    const {
      location: {
        search,
      },
    } = this.props;
    const {
      query,
    } = queryString.parse(search);
    if (query && !this.state.inputValue) {
      this.setState({
        inputValue: decodeURIComponent(query),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;
    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;
    const {
      query,
    } = queryString.parse(search);
    const {
      query: prevSearchValue,
    } = queryString.parse(prevSearch);
    if (prevSearchValue !== query) {
      this.setState({
        inputValue: query ? decodeURIComponent(query) : '',
      });
    }
  }

  onInputChange = e => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      location: {
        search,
      },
      history,
    } = this.props;
    const {
      inputValue,
    } = this.state;
    const {
      query,
      first_char, // eslint-disable-line
      geo, // eslint-disable-line
      genre, // eslint-disable-line
      ...otherParams
    } = queryString.parse(search);

    const encodedValue = encodeURIComponent(inputValue);

    if (query === encodedValue) return;

    history.push(`?${queryString.stringify({ ...otherParams, ...inputValue && { query: encodedValue } })}`);
  };

  resetFilters = () => {
    const {
      location: {
        search,
      },
      history,
    } = this.props;
    const {
      query,
      type,
    } = queryString.parse(search);
    history.push(`?${queryString.stringify({ type, query })}`);
  };

  toggleFilter = (key, value) => {
    const {
      location: {
        search,
      },
      history,
    } = this.props;
    const {
      query, // eslint-disable-line
      page, // eslint-disable-line
      ...otherParams
    } = queryString.parse(search);

    if (this.state.inputValue) {
      this.setState({
        inputValue: '',
      });
    }


    const decodedQueryValue = decodeURIComponent(otherParams[key] || '');

    if (value === 'all') {
      if (otherParams[key] === undefined) return;
      history.push(`?${queryString.stringify({ ...otherParams, [key]: undefined })}`);
      return;
    } else if (decodedQueryValue !== value) {
      history.push(`?${queryString.stringify({ ...otherParams, [key]: encodeURIComponent(value) })}`);
    }
  };

  render() {
    const {
      theme: {
        controls: {
          input: {
            const: {
              sizes: {
                [size]: inputSizeAtoms,
              },
            },
          },
        },
      },
      location: {
        search,
      },
      disabledFilters,
      allowNoindex,
      isLoading,
      ...listPageProps
    } = this.props;

    const queryParams = queryString.parse(search);

    const scope = resolveScopedStyles(
      <scope>
        <style jsx>{`
          .${styles.searchButton}
            height ${inputSizeAtoms.height}
            margin-left 10px
        `}</style>
      </scope>
    );

    return (
      <Fragment>
        <Indent bottom={40} />
        {allowNoindex &&
          <Noindex
            topicsLength={listPageProps.topicsData?.meta.filtered_count}
          />
        }
        <Section>
          <Block desktop={9} mobile={12} >
            <form
              className={styles.searchStars}
              onSubmit={this.handleSubmit}
            >
              <SearchInput
                placeholder='поиск...'
                size={size}
                value={this.state.inputValue}
                onChange={this.onInputChange}
                withoutIcon
              />
              <Button
                className={scope.wrapClassNames(styles.searchButton)}
                typeAttribute='submit'
              >
                Найти
              </Button>
            </form>
          </Block>
        </Section>
        <Filters
          queryParams={queryParams}
          disabledFilters={disabledFilters}
          resetFilters={this.resetFilters}
          toggleFilter={this.toggleFilter}
        />
        <TopicsList isLoading={isLoading} {...listPageProps} />
        <scope.styles />
      </Fragment>
    );
  }
}

ListPageWithFilters.propTypes = {
  topicsData: PropTypes.exact({
    content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    meta: PropTypes.object,
  }),
  genre: PropTypes.array,
  geo: PropTypes.array,
  allowNoindex: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  disabledFilters: PropTypes.array,
  isLoading: PropTypes.bool,
  theme: PropTypes.object,
};

ListPageWithFilters.defaultProps = {
  children: () => null,
};

export default withTheme(withRouter(ListPageWithFilters));
