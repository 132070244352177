import { useContext } from 'react';
import PropTypes from 'prop-types';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import EmbedContext from 'site/components/Embed';

import { checkNavigationHeaders } from 'site/utils';

import ContentWithContents from './ContentWithContents';

function StarsWrapper(props) {
  const { isHasContents, content, ...otherProps } = props;
  const embedMode = useContext(EmbedContext);

  if (!Array.isArray(content) || content.length < 1) return null;

  if (!embedMode && isHasContents && checkNavigationHeaders(content)) {
    return <ContentWithContents content={content} {...otherProps} />;
  }

  return (
    <MaxWidthWrapper>
      {props.children}
    </MaxWidthWrapper>
  );
}

StarsWrapper.propTypes = {
  isHasContents: PropTypes.bool,
  content: PropTypes.array,
};

export default StarsWrapper;
