import { bebop as bebopApi } from 'core/api/definitions/bebop';

export default () => {
  return {
    queryKey: ['bebopApi', 'getAlphabet', {
      topic_type: 'artist_group,artist_person',
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params).catch(err => console.warn(err)),
    select: data => {
      Object.keys(data).forEach(groupKey => {
        if (data[groupKey].length < 1) delete data[groupKey];
      });
      return data;
    },
  };
};
