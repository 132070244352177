import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/tagTopics';

import listPageTopicsQuery from 'site/queries/listPageTopicsQuery';

import { filterRequiredParams } from 'core/utils/api';

import queryString from 'core/libs/query-string';

import { RUBRICS_PAGE_LIMIT, NEWS_RUBRIC_LIMIT, STARS_PAGE_LIMIT, STARS_PAGE_LIMIT_MOBILE } from 'site/constants';

import CardVertical from 'site/cards/CardVertical';
import CardStory from 'site/cards/CardStory';
import CardMainHero from 'site/cards/CardMainHero';

import { QUERY_TYPE_MAP } from '../constants';
import { isSpecialTagType } from '../utils';


const include = filterRequiredParams([CardVertical, CardMainHero, CardStory], 'include');
const fields = filterRequiredParams([CardVertical, CardMainHero, CardStory], 'fields');

export default function useTopicsData({ tag, isDesktop, location, match, history }) {
  const type = queryString.parse(location.search).type;
  const { withSearch, requestType } = QUERY_TYPE_MAP[type] || QUERY_TYPE_MAP.default;

  const tagType = tag?.attributes?.tag_type;
  const enableTopicsForFilters = !!tag && isSpecialTagType(tagType) && withSearch;

  const queryParams = enableTopicsForFilters
    ? listPageTopicsQuery({
      limit: isDesktop ? STARS_PAGE_LIMIT : STARS_PAGE_LIMIT_MOBILE,
      disabledFilters: [tagType],
      match,
      location,
    }) : topicsQuery({
      limit: requestType === 'news' ? NEWS_RUBRIC_LIMIT : RUBRICS_PAGE_LIMIT,
      topic_type: requestType,
      include,
      fields,
      location,
      history,
      match,
    });

  const { data: topics, isLoading } = useRequest(queryParams);

  if (isLoading) return { isLoading: true };
  return { topicsData: topics };
}
