import PropTypes from 'prop-types';
import { useRef } from 'react';

import { resolve } from 'core/utils/env';

import { withBreakpoint } from 'core/components/breakpoint';
import InfiniteTopic from 'core/components/InfiniteTopic';
import Topic from 'core/components/Topic';
import GameCompare from 'core/components/GameCompare';

import { isCompareTopic } from 'core/components/GameCompare/utils';

import { metaBuilder } from 'site/utils';

import YaMetrikaScrollPercent from 'site/components/YaMetrikaScrollPercent';
import { MaxWidthWrapper, PageIndent, Indent } from 'site/components/Wrappers';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import TopicSkeleton from './TopicSkeleton';

import useTopicContent from './useTopicContent';


function topicRenderer(topic, enhancedTopic, wrapperRef, additionalTopics) {
  const {
    content,
    content: {
      attributes: {
        topic_type: topicType,
      },
    },
  } = enhancedTopic;

  const isGameCompare = isCompareTopic(topicType);

  return (
    <PageIndent>
      <style jsx>{`
        .sendWavesToBack
          // Необходимо, чтобы волна из TopicHeader не перекрывала основной контент.
          position relative
      `}</style>
      <YaMetrikaScrollPercent wrapperRef={wrapperRef}>
        <TopicHeader content={content} isGameCompare={isGameCompare} />
        {!isGameCompare && (
          <div className='sendWavesToBack'>
            <TopicContent content={content} />
            <TopicFooter {...additionalTopics} />
          </div>
        )}
        {isGameCompare && (
          <MaxWidthWrapper>
            <Indent top={20} />
            <GameCompare content={content} />
          </MaxWidthWrapper>
        )}
      </YaMetrikaScrollPercent>
    </PageIndent>
  );
}


function TopicPage(props) {
  const {
    isDesktop,
    location,
    match,
    history,
  } = props;

  const withInfinityScroll = location.pathname.includes('news');
  const wrapperRef = useRef(null);

  const {
    content,
    newsFeed,
    topicsWithStars,
    similarStars,
    isLoading,
    topicsWithStarsIsLoading,
    similarStarsIsLoading,
  } = useTopicContent({ location, match, history, isDesktop });

  if (isLoading) return <TopicSkeleton />;

  if (withInfinityScroll) {
    return (
      <InfiniteTopic
        content={content}
        rcmBlockId={resolve({
          // Todo: Сделать айдишку для стейджей!
          '*': 'a8b27cd9146d44d49c66af82763cb7cf',
          'production': isDesktop ? 'a8b27cd9146d44d49c66af82763cb7cf' : '138199bd3d4e445e80d0a6cd3b1830b2',
        })}
        topic_type='news'
      >
        {(t, e) => topicRenderer(t, e, wrapperRef, {
          topicsWithStars,
          similarStars,
          newsFeed,
          topicsWithStarsIsLoading,
          similarStarsIsLoading,
        })}
      </InfiniteTopic>
    );
  }

  return (
    <Topic
      content={content}
      titleBuilder={(topic) => metaBuilder(topic, 'title')}
      descriptionBuilder={(topic) => metaBuilder(topic, 'description')}
      rcmBlockId={resolve({
        // Todo: Сделать айдишку для стейджей!
        '*': '6bba4cec7d684a93a15bba5ac2b7bd1d',
        'production': isDesktop ? '6bba4cec7d684a93a15bba5ac2b7bd1d' : 'a758152465d240a59145cc3da95b3b52',
      })}
    >
      {(t, e) => topicRenderer(t, e, wrapperRef, {
        topicsWithStars,
        similarStars,
        newsFeed,
        topicsWithStarsIsLoading,
        similarStarsIsLoading,
      })}
    </Topic>
  );
}

TopicPage.propTypes = {
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(TopicPage);
