import { bebop as bebopApi } from 'core/api/definitions/bebop';

function listPageTagsQuery({ tagType, offset = 0 }) {
  return {
    queryKey: ['bebopApi', 'getTags', {
      with_filtered_count: 1,
      sort: 'title',
      type: tagType,
      offset,
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params).catch(err => console.warn(err)),
  };
}

/**
 * Если тегов больше чем может отдать API за один запрос,
 * формируем массив запросов с offset.
 */
function additionalTagsQueries({ firstPart, tagType }) {
  if (!firstPart) return [];

  const limitPerRequest = firstPart?.data?.length || 0;
  const filteredCount = firstPart?.meta?.filtered_count || 0;

  const offsets = [];

  for (let i = limitPerRequest; i < filteredCount; i += limitPerRequest) {
    offsets.push(i);
  }

  return filteredCount > limitPerRequest
    ? offsets.map(offset => listPageTagsQuery({ tagType, offset }))
    : [];
}

export { additionalTagsQueries };
export default listPageTagsQuery;
