import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import Feed from 'core/components/Feed';
import ListPage from 'core/components/ListPage';
import H1 from 'core/components/H1';

import { withNonPureBreakpoint } from 'core/components/breakpoint';

import { getOffset } from 'core/utils/url-helper';
import { renderError } from 'core/utils/app-status-helper';

import { Indent, MaxWidthWrapper, PageIndent } from 'site/components/Wrappers';

import CardTag from 'site/cards/CardTag';


function GenresPage({ isMobile, location, history }) {
  const limit = isMobile ? 20 : 40;

  const { data: rawTags, isLoading: rawTagsAreLoading } = useRequest({
    queryKey: ['bebopApi', 'getTags', {
      with_filtered_count: 1,
      type: 'genre',
      sort: 'title',
      limit,
      offset: getOffset(location.search, limit),
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params).catch(err => renderError(err, history)),
  });

  return (
    <PageIndent>
      <MaxWidthWrapper>
        <H1>Жанры</H1>
        <Indent top={30} bottom={60} />
        <ListPage
          title='Список всех музыкальных жанров и стилей'
          description='Список музыкальных жанров, стилей и направлений с подробным описанием, отличиями и особенностями звучания.'
          rawData={rawTags}
          limit={limit}
          isLoading={rawTagsAreLoading}
        >
          {({ content }) => {
            if (!content?.length) return null;

            return (
              <Feed
                content={content}
                interitemSpacing={20}
                columns={2}
                grid
                card={CardTag}
              />
            );
          }}
        </ListPage>
      </MaxWidthWrapper>
    </PageIndent>
  );
}

GenresPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withNonPureBreakpoint(GenresPage);
