import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { tagQuery } from 'core/queries/tagTopics';

import queryString from 'core/libs/query-string';

import { withBreakpoint } from 'core/components/breakpoint';
import PageLoader from 'core/components/Loader/PageLoader';

import TypeSwitcher from 'site/components/TypeSwitcher';
import ListPageWithFilters from 'site/components/ListPageWithFilters';

import PageWrapper from './PageWrapper';
import TopicsList from './TopicsList';

import useTopicsData from './hooks/useTopicsData';
import useTypeSwitcher from './hooks/useTypeSwitcher';

import { QUERY_TYPE_MAP } from './constants';
import { isSpecialTagType } from './utils';


function TagPage({ isDesktop, location, match, history }) {
  const searchType = queryString.parse(location.search).type;
  const { queryType, withSearch } = QUERY_TYPE_MAP[searchType] || QUERY_TYPE_MAP.default;

  const { data: tag, isLoading: tagIsLoading } = useRequest(tagQuery({ match, history }));

  const { topicsData, isLoading: topicDataIsLoading } = useTopicsData({
    isDesktop,
    tag,
    location,
    match,
    history,
  });

  const {
    typeSwitcherNews,
    typeSwitcherStars,
    typeSwitcherArticles,
    isLoading: typeSwitcherIsLoading,
  } = useTypeSwitcher({ location, match, history });

  if (tagIsLoading || topicDataIsLoading) return <PageLoader />;

  const tagType = tag?.attributes?.tag_type;
  const filtersDataEnabled = !!tag && isSpecialTagType(tagType) && withSearch;

  return (
    <PageWrapper tag={tag} topicType={queryType}>
      {() => (
        <Fragment>
          {!typeSwitcherIsLoading &&
            <TypeSwitcher
              news={typeSwitcherNews}
              stars={typeSwitcherStars}
              articles={typeSwitcherArticles}
              topicType={queryType}
              tagType={tagType}
            />
          }
          {filtersDataEnabled ? (
            <ListPageWithFilters
              topicsData={topicsData}
              disabledFilters={[tagType]}
              allowNoindex
              isLoading={!!topicDataIsLoading}
            />
          ) : (
            <TopicsList
              type={searchType}
              topicsData={topicsData}
              isLoading={!!topicDataIsLoading}
            />
          )}
        </Fragment>
      )}
    </PageWrapper>
  );
}

TagPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(TagPage);
