import PropTypes from 'prop-types';

import Link from 'site/components/Link';
import Button from 'core/components/Button';

import { Indent } from 'site/components/Wrappers';

import styles from './index.styl';


const QUERY_TYPE_MAP = [
  {
    title: 'Новости',
    queryType: 'news',
  },
  {
    title: 'Статьи',
    queryType: 'articles',
  },
  {
    title: 'Артисты',
    queryType: 'stars',
    tags: ['genre', 'geo'],
  },
];

export default function TypeSwitcher(props) {
  const {
    news,
    stars,
    articles,
    topicType,
    tagType,
  } = props;

  if (!news?.length && !stars?.length && !articles?.length) return null;

  return (
    <div className={styles.btn}>
      {QUERY_TYPE_MAP.map(({ title, queryType, tags }) => {
        const noTopicsToShow = !props[queryType]?.length;
        const tagCheckNeeded = !!tagType && Array.isArray(tags);
        const isIncompatibleTag = tagCheckNeeded && !tags.includes(tagType);

        if (noTopicsToShow || isIncompatibleTag) return null;

        return (
          <Indent right={10} key={queryType}>
            <Link to={queryType === 'news' ? '#' : `?type=${queryType}`}>
              <Button
                type={topicType === queryType ? 'secondaryGrey' : 'ghost'}
                size='medium'
              >
                {title}
              </Button>
            </Link>
          </Indent>
        );
      })}
    </div>
  );
}

TypeSwitcher.propTypes = {
  /** Тип топика */
  topicType: PropTypes.string,
  /** Тип тега */
  tagType: PropTypes.string,
  /** @ignore */
  news: PropTypes.array,
  /** @ignore */
  stars: PropTypes.array,
  /** @ignore */
  articles: PropTypes.array,
};

export { TypeSwitcher as StorybookComponent };
