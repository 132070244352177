import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';

import Link from 'site/components/Link';
import SmartImage from 'core/components/SmartImage';

import { buildTagTitlesString } from 'site/utils';

import types from './types';
import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'published_at',
  'topic_type',
  'alternative_headline',
  'list_headline',
];

const requiredPayloadImports = ['image', 'tags'];

const relationships = resolveRelationships(['image', 'tags'], null, {
  image: {
    versions: {},
  },
  tags: [],
});

function CardSimple(props) {
  const {
    content,
    tagsFilterCallback,
    type,
    size,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    remote_image: remoteImage,
    link_attrs: linkAttrs,
  } = content.extra || {};

  const {
    imagePosition,
    imageWidth,
    imageRatio,
  } = types[type] || {};

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    tags,
  } = relationships(content);

  const isSpecproject = topicType === 'specproject';
  const tagsString = tagsFilterCallback
    ? buildTagTitlesString(tags, tagsFilterCallback)
    : null;

  return (
    <div
      className={cx(
        'container',
        styles[`_imgPosition_${imagePosition}`],
        styles[`_size_${size}`],
      )}
    >
      <style jsx>{`
        .${styles.headline}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}

          .container:hover &
            color ${theme.colors.blue60}

        .${styles.altHeadline}
          font-family ${theme.fonts.text}
          color ${theme.colors.gray30}

        .${styles.tags}
          font-family ${theme.fonts.text}
          color ${theme.colors.gray40}
        `}
      </style>
      <Link
        to={link}
        className={styles.wrapper}
        type='secondary'
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
        {...isSpecproject && { target: '_blank' }}
      >
        {(cover || remoteImage) && (
          <div className={styles.img}>
            <SmartImage
              width={imageWidth}
              src={cover}
              previewSrc={previewCover}
              url={remoteImage}
              aspectRatio={imageRatio}
            />
          </div>
        )}
        <div className={styles.title}>
          <div className={styles.headline}>
            {headline || listHeadline}
          </div>
          {altHeadline &&
            <div className={styles.altHeadline}>
              {altHeadline}
            </div>
          }
          {!!tagsString && (
            <div className={styles.tags}>{tagsString}</div>
          )}
        </div>
      </Link>
    </div>
  );
}

CardSimple.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /**
   * Коллбек, который будет передан в tags.filter().
   * Если он не передан, теги не будут отображаться в карточке.
   */
  tagsFilterCallback: PropTypes.func,
  /** @ignore */
  theme: PropTypes.object,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** Размер карточки */
  size: PropTypes.oneOf(['s', 'm']),
};

CardSimple.defaultProps = {
  type: 0,
  size: 's',
};

const Card = withTheme(CardSimple);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as CardSimple0 };
export const CardSimple1S = bindProps({ type: 1 })(Card);
export const CardSimple2S = bindProps({ type: 2 })(Card);
export const CardSimple1M = bindProps({ type: 1, size: 'm' })(Card);
export const CardSimple2M = bindProps({ type: 2, size: 'm' })(Card);

export { CardSimple as StorybookComponent };

export default Card;
