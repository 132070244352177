import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawAuthorsQuery } from 'core/queries/authorsPage';

import AuthorsPage from 'core/components/AuthorsPage';
import H1 from 'core/components/H1';

import { Indent, MaxWidthWrapper, PageIndent } from 'site/components/Wrappers';
import CardAuthorsPage from 'site/cards/CardAuthor/AuthorsPageCard';


function Authors({ location, history }) {
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery({ location, history }));

  return (
    <PageIndent>
      <MaxWidthWrapper>
        <H1>Авторы</H1>
        <Indent top={30} bottom={60}>
          <AuthorsPage
            rawAuthors={rawAuthors}
            card={CardAuthorsPage}
            columns={1}
            interitemSpacing={10}
            isLoading={isLoading}
          />
        </Indent>
      </MaxWidthWrapper>
    </PageIndent>
  );
}

Authors.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default Authors;
